import React from 'react';
import './Footer.css';
import Github from '../assets/github.png';
import Instagram from '../assets/instagram.png';
import Linkedln from '../assets/linkedin.png';
import Logo from '../assets/logo.png';
import { FiTwitter } from 'react-icons/fi';
const footer = () => {
  return (
    <div className='footer-container'>
      <hr />
      <div className='footer'>
        <div className='social-links'>
          <a href='https://github.com/Stephenadebayoy'>
            <img src={Github} alt='' />
          </a>
          <a href='https://www.instagram.com/i_am_stephanos/' blank=''>
            <img src={Instagram} alt='' />
          </a>
          <a href='https://www.linkedin.com/in/adebayo-stephen-152a7922b'>
            <img src={Linkedln} alt='' />
          </a>
          <a href='https://mobile.twitter.com/i_am_stephanos' className='twi'>
            <FiTwitter />
          </a>
        </div>
        <div
          className='logo'
          data-aos='fade-up'
          data-aos-anchor-placement='bottom-bottom'
        >
          <img src={Logo} alt='' />
        </div>
      </div>
      <div className='blur blur-f-1'></div>
      <div className='blur blur-f-2'></div>
    </div>
  );
};

export default footer;
